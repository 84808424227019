import * as React from "react"
import appStore from '../resources/black.svg'
import geoLogo from '../resources/geoLogo.svg'
import FooterLayout from "../components/footer"

export default function Home() {
  return (
    <FooterLayout>
      <div style={{textAlign: "center"}}>
          <h1 className="geo-title">Geognosis</h1>
          <img class="geo-logo" src={geoLogo} alt="Geognosis logo" />
          <span />
          <p class="geo-desc">Learn geography through play</p>
          <a href="https://apps.apple.com/us/app/geognosis/id1011172650?mt=8">
            <img src={appStore} style={{width: "140px" }} alt="Download Geognosis on the App Store" />
          </a>
      </div>
    </FooterLayout>
  );
}